import React from "react";
import {
  Typography,
  Box,
  ListItem,
  ListItemText,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PritLogo from "../../../../assets/prit-logo.png";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  pritLogoImg: {
    cursor: "pointer",
    maxWidth: "100%",
    height: "auto",
    "&:hover": {
      opacity: 0.7,
      transition: "opacity 0.3s ease-in-out",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(6, 4),
  },
  title: {
    fontSize: 37,
    textAlign: "left",
    color: "#201D4D",
    fontFamily: "Nunito",
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    width: "100%",
    maxWidth: "900px",
    [theme.breakpoints.down("md")]: {
      fontSize: 35,
      marginBottom: theme.spacing(3),
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "900px",
    padding: theme.spacing(1.5, 3),
    marginBottom: theme.spacing(2),
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid #959595",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.10)",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    cursor: "pointer",
  },
  listItemText: {
    fontSize: 16,
    color: "#333",
    fontFamily: "Nunito",
    fontWeight: 400,
  },
  iconButton: {
    color: "#333",
  },
}));

const AnswerSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { title, questions } = location.state || {};


  const handleNavigate = (questionData) => {
    navigate("/faq/answer", {
      state: {
        question: questionData,
      },
    });
  };

  return (
    <div>
      <Box className={classes.headerBox}>
        <img
          src={PritLogo}
          alt="Prit Logo"
          className={classes.pritLogoImg}
          onClick={() => navigate("/faq")}
        />
      </Box>
      <div className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        {questions.map((item, index) => (
          <ListItem
            key={index}
            className={classes.listItem}
            onClick={() => handleNavigate(item)}
            aria-label={`Pergunta: ${item.question}`}
          >
            <ListItemText
              primary={item.question}
              classes={{ primary: classes.listItemText }}
            />
            <IconButton className={classes.iconButton} edge="end">
              <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
            </IconButton>
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export default AnswerSection;
